@font-face {
  font-family: 'Marianne';
  src: local('Marianne'), url(./fonts/Marianne/Marianne-Regular.otf) format('opentype');
}

html {
  overflow-y: auto;
}

body {
  margin: 0;
  font-family: 'Marianne', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #dfebf7;
  padding-right: 0 !important;
}

code {
  font-family: 'Marianne', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
