.svg-logo {
    display: inline-block;
    top: 0;
    left: 0;
}
.cls-1{
    isolation:isolate;
}
.cls-2{
    fill:#f5fcff;
}
.cls-3{
    fill:#e32d4c;
    opacity:0.39;
}
.cls-12,.cls-13,.cls-14,.cls-3{
    mix-blend-mode:multiply;
}
.cls-4{
    fill:#d53e4f;
    stroke:#be3646;
}
.cls-10,.cls-11,.cls-16,.cls-17,.cls-18,.cls-19,.cls-4,.cls-7,.cls-8,.cls-9{
    stroke-miterlimit:10;
}
.cls-5{
    font-size:11.13px;
    fill:#fff;
}
.cls-20,.cls-22,.cls-23,.cls-24,.cls-5{
    font-family:Helvetica, sans-serif;
}
.cls-6{
    letter-spacing:-0.05em;
}
.cls-7{
    fill:#f46d43;
    stroke:#db6643;
}
.cls-8{
    fill:#fdae60;
    stroke:#e29b56;
}
.cls-9{
    fill:#9e0142;
    stroke:#8d013a;
}
.cls-10{
    fill:#5e4fa2;
    stroke:#534591;
}
.cls-11{
    fill:#3288bd;
    stroke:#2c79a9;
}
.cls-12{
    opacity:0.92;
    font-size:53.07px;
    font-family:"Champagne&Limousines-Bold", "Champagne & Limousines", sans-serif;
}
.cls-12,.cls-15,.cls-20,.cls-21{
    fill:#032c3a;
}
.cls-12,.cls-21{
    font-weight:700;
}
.cls-13{
    fill:#0888c0;
}
.cls-13,.cls-14{
    opacity:0.4;
}
.cls-14{
    fill:#44c3a1;
}
.cls-16{
    fill:#f9d67d;
    stroke:#e0be68;
}
.cls-17{
    fill:#d2dd83;
    stroke:#c2cc7f;
}
.cls-18{
    fill:#abdda4;
    stroke:#98c692;
}
.cls-19{
    fill:#66c2a5;
    stroke:#5aad93;
}
.cls-20,.cls-21{
    font-size:13px;
}
.cls-21{
    font-family:Helvetica-Bold, Helvetica, sans-serif;
}
.cls-22,.cls-23,.cls-24{
    font-weight:400;
}
.cls-23,.cls-24{
    opacity:0.5;
}
.cls-24{
    letter-spacing:-0.07em;
}
